import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
    //
  }

  canActivate(): Observable<boolean> {

    let initialPage = "/quiz";

    return this.auth.isLogged().pipe(map((authenticated: boolean ) => {
      if (authenticated) {
        return true;
      }
      console.log("User not authenticated - going to public area");
      this.router.navigate([initialPage]);
      return false;
    }), catchError(() => {
      console.log("error on route");
      this.router.navigate([initialPage]);
      return of(false);
    }));

  }
}
