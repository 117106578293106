import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { map } from 'rxjs/operators'

// https://www.positronx.io/ionic-firebase-authentication-tutorial-with-examples/

import firebase from 'firebase/compat/app';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {

  private phoneSMSverificationFunc: any;

  public userInfo: any;

  private uids: string[] = [];

  constructor(public afAuth: AngularFireAuth) {

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        // console.log("authState subscribe", user);
        this.appendUID(user.uid);
      }
    });

    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        // console.log("change state", user);
        this.appendUID(user.uid);
      }
    });
  }

  appendUID(uid) {
    if (uid) {
      if (this.uids.indexOf(uid) < 0) {
        this.uids.push(uid);
        // console.log(this.uids);
      }
    }
  }

  getAnonymousUIDs() {
    return this.uids.filter((v, i, a) => a.indexOf(v) === i);
  }

  getTokenizedHeaders() {
    return new Promise(function (resolve, reject) {

      this.afAuth.authState.subscribe(user => {

        this.userInfo = user;

        if (user) {
          user.getIdToken().then((token) => {

            const headers = new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            });

            resolve({
              headers: headers
            });

          });
        }
        else {
          reject();
        }
      });
    }.bind(this));
  }

  // Shouldn't be used. Use idTokens.
  getUID(): Promise<string> {
    return new Promise(function (resolve, reject) {
      this.afAuth.authState.subscribe((user) => {
        // console.log(user);
        if (user) {
          resolve(user.uid);
        }
        else {
          resolve()
        }
      });
    }.bind(this));

  }

  isLogged(): Observable<boolean> {
    return this.afAuth.authState.pipe(map((user) => {
      if (user) {
        return !user.isAnonymous;
      }
      return false;
    }));
  }

  /*
  getUser(): Promise<firebase.User>{
    return new Promise(function(resolve, reject){
      this.afAuth.authState.subscribe(user => {
        this.afAuth.idToken.subscribe(idToken => {
          this.idToken = idToken;
          console.log(this.idToken);
          resolve(user as firebase.User);
        });
      });
    }.bind(this));
  }*/

  getUser(): any {
    return this.userInfo as firebase.User;
  }

  // https://firebase.google.com/docs/auth/web/anonymous-auth#convert-an-anonymous-account-to-a-permanent-account
  signInAnonymously(): Promise<any> {
    return new Promise(function (resolve, reject) {
      this.afAuth.signInAnonymously().then((userInfo) => {
        this.userInfo = userInfo.user;
        resolve(userInfo);
      }).catch(err => {
        console.log(err);
        resolve(err);
      });
    }.bind(this));
  }

  signInWithEmailAndPassword(credentials) {
    // console.log('Sign in with email');
    return this.afAuth.signInWithEmailAndPassword(credentials.email,
      credentials.password);
  }

  sendSignInLinkToEmail(email) {
    window.localStorage.setItem('emailForSignIn', email);
    const actionCodeSettings = {
      url: "https://" + window.location.hostname + "/login",
      handleCodeInApp: true
    };
    return this.afAuth.sendSignInLinkToEmail(email, actionCodeSettings);
  }

  signInWithEmailLink(emailLink) {
    return new Promise(function (resolve, reject) {
      // Confirm the link is a sign-in with email link.
      if (this.afAuth.isSignInWithEmailLink(emailLink)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        // The client SDK will parse the code from the link for you.
        if(email){
          this.afAuth.signInWithEmailLink(email, window.location.href).then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            resolve(result);
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            console.error(error);
            reject(error);
          });
        }
        else{
          reject("No email found in local storage. Check if user opened link in a another browser or account");
        }
      }
      else{
        resolve("Invalid Firebase email link");
      }
    }.bind(this));
  }

  signOut() {
    return this.afAuth.signOut();
  }

  signUp(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(() => {
        //
      })
      .catch(error => console.log(error));
  }

  signInWithPhoneNumber(phoneNumber, recaptchaVerifier) {
    return new Promise(function (resolve, reject) {
      this.afAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier).then((result) => {
        this.phoneSMSverificationFunc = result;
        resolve(result);
      }).catch(err => {
        console.log(err);
        resolve(err);
      });
    }.bind(this));
  }

  confirmSMS(smsCode) {
    return new Promise(function (resolve, reject) {
      this.phoneSMSverificationFunc.confirm(smsCode).then((userInfo) => {
        this.userInfo = userInfo.user;
        // console.log(this.userInfo);
        resolve(userInfo);
      }).catch(err => {
        console.log(err);
        resolve(err);
      });
    }.bind(this));
  }

  signInWithTwitter() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    );
  }

  signInWithFacebook() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  signInWithGoogle() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
  }

  /*
  signInWithGoogle() {
      console.log('Sign in with Google');
      return this.oauthSignIn(new firebase.auth.GoogleAuthProvider());
  }

  signInWithFacebook() {
      console.log('Sign in with Facebook');
      return this.oauthSignIn(new firebase.auth.FacebookAuthProvider());
  }

  private oauthSignIn(provider: AuthProvider) {
    if (!(<any>window).cordova) {
      return this.afAuth.auth.signInWithPopup(provider);
    } else {
      return this.afAuth.auth.signInWithRedirect(provider)
      .then(() => {
        return this.afAuth.auth.getRedirectResult().then( result => {
          // This gives you a Google Access Token.
          // You can use it to access the Google API.
          let token = result.credential.accessToken;
          // The signed-in user info.
          let user = result.user;
          console.log(token, user);
        }).catch(function(error) {
          // Handle Errors here.
          alert(error.message);
        });
      });
    }
  }
  */

}
