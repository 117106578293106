import { Component } from '@angular/core';
import { LibertaCommonProvider } from './services/liberta-common/liberta-common.service';
import { ThemeService } from './services/themeManager/theme-manager.service';

import { PushNotificationService } from './services/push-notification/push-notification.service';
import { AuthService } from './services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { arrayUnion } from '@angular/fire/firestore'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private themeService: ThemeService,
    private pushNotificationService: PushNotificationService,
    private afFirestore: AngularFirestore,
    private auth: AuthService) {
      this.auth.isLogged().subscribe((logged) => {
        if(logged){
          this.auth.getUID().then((uid) => {
            console.log("User logged", uid);
            this.pushNotificationService.requestPermission().subscribe(
              (token) => {
                // User granted permission to send messages
                this.afFirestore.collection("accounts").doc(uid).update({
                  firebase_message_token: arrayUnion(token)
                });
                this.afFirestore.collection("accounts").doc(uid).set({
                  enable_notifications: true
                }, {merge: true});
              },
              (error) => {
                console.error(error);
              },  
            );
            this.pushNotificationService.receiveMessage();
          });
        }
      });
  }

  ngOnInit(){
    this.themeService.activeTheme(LibertaCommonProvider.getCompanyName());
  }
}
