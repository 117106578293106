import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import 'firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  pushNotificationToken: string = null;

  constructor(
    private afMessaging: AngularFireMessaging) {
  }

  requestPermission() {
    return this.afMessaging.requestToken;
  }

  receiveMessage(){
    this.afMessaging.messages.subscribe((payload) => {
      console.log(payload);
    });
  }

}
